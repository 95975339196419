import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/client-images/dyj_logo.png";
import imgL1LogoWhite from "../../assets/client-images/dyj_logo.png";

import imgWebp from "../../assets/client-images/dyj_logo.webp";


const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
        <picture>
          <source srcset={imgWebp} type="image/webp" />
          <img height='45px' width='178px' src={imgL1LogoWhite} alt="" />
        </picture>
      ) : (
        <picture>
          <source srcset={imgWebp} type="image/webp" />
          <img height='45px' width='178px' src={imgL1Logo} alt="" />
        </picture>

      )}
    </Link>
  );
};

export default Logo;
